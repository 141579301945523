// This utility function is only used and specifically crafted for our catchall Route [..._].vue
// It creates a cache key based on the route params and query params.

import { type RouteLocationNormalizedLoaded } from "#vue-router"

export const createCacheKey = ({
  params,
  query,
}: RouteLocationNormalizedLoaded): string => {
  if (query?.alchemy_preview_mode === "true") {
    return `${Date.now()}`
  }
  if (!Array.isArray(params._)) {
    return params._
  } else {
    return params._.filter((param) => param !== "").join("-")
  }
}
